import { render, staticRenderFns } from "./default_messages.vue?vue&type=template&id=339e7266&scoped=true&"
import script from "./default_messages.vue?vue&type=script&lang=js&"
export * from "./default_messages.vue?vue&type=script&lang=js&"
import style0 from "./default_messages.vue?vue&type=style&index=0&id=339e7266&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "339e7266",
  null
  
)

export default component.exports