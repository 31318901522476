<template>
  <div class="project-overview">
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-projects-messages-detail-vue
          v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
        ></adminvue-projects-messages-detail-vue>
        <projectcoordinators-message-vue
          v-else-if="getActiveUserData.userRoles == 'projectcoordinators'"
        ></projectcoordinators-message-vue>
        <salesteamleadvue-projects-messages-detail-vue
          v-else-if="(getActiveUserData.userRoles == 'salesteamlead') || (getActiveUserData.userRoles == 'salesmanager')"
        ></salesteamleadvue-projects-messages-detail-vue>
        <salesperson-projects-messages-detail-vue
          v-else-if="(getActiveUserData.userRoles == 'salesperson') || (getActiveUserData.userRoles == 'marketingmanager') "
        ></salesperson-projects-messages-detail-vue>
        <client-projects-messages-detail-vue
          v-else-if="getActiveUserData.userRoles == 'client'"
        ></client-projects-messages-detail-vue>
        <default-projects-messages-detail-vue
          v-else
        ></default-projects-messages-detail-vue>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import adminvue from "./childrens/admin_messages";
import clientvue from "./childrens/client_messages";
import defaultvue from "./childrens/default_messages";
import salespersonvue from "./childrens/salesperson_messages";
import salesteamleadvue from "./childrens/salesteamlead_messages";
import projectcoordinatorsvue from "./childrens/projectcoordinators_message.vue";


export default {
  components: {
    "adminvue-projects-messages-detail-vue": adminvue,
    "salesteamleadvue-projects-messages-detail-vue": salesteamleadvue,
    "salesperson-projects-messages-detail-vue": salespersonvue,
    "client-projects-messages-detail-vue": clientvue,
    "projectcoordinators-message-vue" : projectcoordinatorsvue,
    "default-projects-messages-detail-vue": defaultvue
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  mounted() {}
};
</script>
