<template>
  <div class="viewDashBoard project-show">
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else key="2" class="meassage-wrapper">
        <div
          class="white-row"
          v-if="
            typeof project_details.data !== 'undefined' &&
              Object.keys(project_details.data).length
          "
        >
          <div class="project-show-details">
            <section class="main-title-header">
              <div class="row">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                  <router-link
                    class="global-para-link2"
                    :to="{
                      path:
                        '/projects/show/' +
                        project_details.data.project_details.id +
                        '/messages'
                    }"
                    tag="h4"
                  >
                    Project #{{ project_details.data.project_details.id }} -
                    <span>
                      {{ project_details.data.project_details.name }}
                    </span>
                  </router-link>
                  <p class="remove-margin-btm">
                    {{ project_details.data.porject_thread_subtitle }}
                    <a
                      @click.prevent="refreshMessages"
                      href=""
                      class="refreshData"
                      ><i class="fas fa-redo-alt"></i
                    ></a>
                  </p>
                </div>
                <div class="col-lg-3 col-xl-3 col-md-3 col-sm-3">
                  <div
                    class="top-date text-xl-right text-lg-right text-md-right text-sm-right"
                  >
                    <p>Posted on {{ project_details.data.created_at }}</p>
                  </div>
                </div>
              </div>
            </section>

            <section class="message-listing">
              <div v-if="load_more_btn" class="col-sm-12">
                <div class="row">
                  <div class="loadMoreBtn">
                    <a
                      @click.prevent="
                        getMessagesData(nextPageNumber, 'loadmore')
                      "
                      href="#"
                      >Load More....</a
                    >
                  </div>
                </div>
              </div>
              <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
              >
                <div key="1" v-if="data_loader" class="white-row loader-route">
                  <div class="lds-sub-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div v-else key="2">
                  <div
                    v-for="(mes, mes_index) in thread_message_detail.data"
                    :key="mes_index"
                    class="thread-row"
                  >
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div class="thread-area">
                          <img
                            class="user-img"
                            src="/images/user-profile-icon.png"
                          />
                          <div class="box-wrap-message">
                            <div class="thread-user-details">
                              <p class="user-name" v-text="mes.user.name"></p>
                              <p
                                class="right-side-para-message"
                                v-text="mes.created_at"
                              >
                                June 4 , 12:12 pm
                              </p>
                              <div
                                class="ThreadeBody"
                                v-html="mes.message"
                              ></div>
                              <div
                                class="files-listing"
                                v-if="
                                  typeof mes.getFiles !== 'undefined' &&
                                    Object.keys(mes.getFiles).length
                                "
                              >
                                <div class="row">
                                  <div
                                    v-for="(file, file_index) in mes.getFiles"
                                    :key="file_index"
                                    class="col-xl-3 col-lg-3 col-md-6 col-sm-6"
                                  >
                                    <div class="file-listing-wrapper">
                                      <div class="file-box">
                                        <div class="img-box">
                                          <img
                                            v-if="file.image_ext === 'zip'"
                                            v-lazy="global_image_url"
                                          />
                                          <img
                                            v-else-if="file.image_ext === 'psd'"
                                            v-lazy="psd_url"
                                          />
                                          <img
                                            v-else-if="
                                              file.image_ext === 'odt' ||
                                                file.image_ext === 'docx'
                                            "
                                            v-lazy="doc_file_url"
                                          />
                                          <img
                                            v-else-if="
                                              file.image_ext === 'xlsx'
                                            "
                                            v-lazy="google_excel_image"
                                          />
                                          <img v-else v-lazy="file.image_url" />
                                        </div>
                                        <div class="icons-wrap">
                                          <div class="icons-bar">
                                            <a
                                              @click.prevent="downloadImage(mes_index,file_index)"
                                              target="_blank"
                                              ><i class="fas fa-download"></i
                                            ></a>
                                          </div>
                                          <div class="icons-bar">
                                            <a
                                              target="_blank"
                                              :href="file.image_url"
                                              ><i class="fas fa-eye"></i
                                            ></a>
                                          </div>
                                          <div class="icons-bar">
                                            <a
                                              href=""
                                              v-confirm="{
                                                loader: true,
                                                ok: dialog =>
                                                  deletFile(dialog, {
                                                    message_index: mes_index,
                                                    file_index: file_index
                                                  }),
                                                cancel: doNothing,
                                                message:
                                                  'Are you sure? You want to delete it.'
                                              }"
                                            >
                                              <i class="fas fa-times"></i>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="file-details-box">
                                        <h5
                                          class="eclipse-para"
                                          v-text="file.filename"
                                        ></h5>
                                        <p>
                                          {{ file.id }} Added by
                                          {{ file.user_name }},
                                          {{ file.created_at }}
                                          {{ file.size }}KB
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </section>

            <!-- <section class="submit-form-area">
              <form
                class="ReplyFormArea"
                enctype="multipart/form-data"
                v-on:submit.prevent="onSubmit($event)"
              >
                <div v-if="disableSubmitBtn" class="onCLikFormLoad">
                  <div class="lds-hourglass"></div>
                </div>
                <div class="form-group">
                  <label class="sub-heading" for="messagebody"
                    >Write Reply</label
                  >
                  <ckeditor
                    v-model="formData.messagebody"
                    :config="editorConfig"
                    :class="{
                      'input-border-errors':
                        $v.formData.messagebody.$dirty &&
                        !$v.formData.messagebody.required
                    }"
                  ></ckeditor>
                </div>

                <dropzone
                  id="foo"
                  ref="createPost"
                  :options="options"
                  v-on:vdropzone-sending="sendingEvent"
                  v-on:vdropzone-success="successEvent"
                  v-on:vdropzone-error="errorEvent"
                  v-on:vdropzone-success-multiple="multipleUploadComplete"
                  v-on:vdropzone-error-multiple="multipleServerError"
                ></dropzone>

                <div class="form-actions">
                  <button
                    :disabled="disableSubmitBtn"
                    type="submit"
                    id="save-form"
                    class="btn btn-primary default-global-btn"
                  >
                    Reply
                  </button>
                  <div v-if="disableSubmitBtn" class="form_submit_loader">
                    <div class="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </form>
            </section> -->
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// import Dropzone from "nuxt-dropzone";
// import "nuxt-dropzone/dropzone.css";
import $ from "jquery";
import { validationMixin } from "vuelidate";
const {
  required,
  minLength,
  email,
  numeric,
  url
} = require("vuelidate/lib/validators");
export default {
  mixins: [validationMixin],
  components: {
    // Dropzone
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  watch: {
    "$route.params.thread_id"(newId, oldId) {
      console.log(newId, oldId);
      this.getThreadProjectDetails(newId);
    },
    "$route.query.reload"(oldId) {
      this.getThreadProjectDetails(this.$route.params.thread_id);
    }
  },
  validations: {
    formData: {
      messagebody: {
        required
      }
    }
  },
  data() {
    return {
      thread_id: this.$route.params.thread_id,

      editMessage: {
        formData: {
          messagebody: null,
          messageid: null
        },
        edit_selected_index: null
      },
      edit_project_message_modal: false,
      hideMessageBox: true,
      unAuthLoadNotice: false,
      dataLoader: true,
      formContainerId: null,
      doc_file_url: "https://s3-us-west-2.amazonaws.com/www.pncworkbench.com/pncworkbenchlogo/file_doc.png",
      google_excel_image: "https://s3-us-west-2.amazonaws.com/www.pncworkbench.com/pncworkbenchlogo/excel.png",
      global_image_url: "https://s3-us-west-2.amazonaws.com/www.pncworkbench.com/pncworkbenchlogo/archive.png",
      psd_url: "https://s3-us-west-2.amazonaws.com/www.pncworkbench.com/pncworkbenchlogo/psd.png",
      ifDataLoaded: false,
      main_thread_details: {},
      thread_reply_data: {},
      pre: window.pre,
      disableSubmitBtn: false,
      formData: {
        project_id: null,
        thread_id: this.$route.params.thread_id,
        messagebody: ""
      },
      editorConfig: {
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"]
          },
          { name: "links" },
          { name: "styles" },
          { name: "colors" },
          { name: "tools" },
          { name: "others" },
          { name: "about" }
        ],
        // The configuration of the editor.
        extraPlugins: "font",
        disableNativeSpellChecker: false,
        versionCheck: false,
      },
      options: {
        addRemoveLinks: true,
        autoProcessQueue: false,
        parallelUploads: 100000,
        timeout: 500000,
        maxFilesize: 500,
        uploadMultiple: true,
        withCredentials: true,
        url: window.api_homeurl + "/thread-message-reply/store"
      },
      nextPageNumber: 1,
      thread_message_detail: {},
      project_details: {},

      load_more_btn: false,
      loader: true,
      data_loader: true
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
    this.getThreadProjectDetails();
  },
  created() {},
  methods: {
    async downloadImage(mes_index,file_index) {
      let file_id = this.thread_message_detail.data[mes_index].getFiles[file_index].id;
      let file_name = this.thread_message_detail.data[mes_index].getFiles[file_index].filename;
      const $this = this;
      $this.$store.dispatch("generalLoader", {
        response: true
      });

      await window
      .axios({
        url:
          "files/download/" +
          file_id +
          "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        method: "GET",
        responseType: "blob" // important
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          file_name
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
        $this.$store.dispatch("generalLoader", {
          response: false
        });
      });
    },
    refreshMessages() {
      this.getThreadProjectDetails();
    },
    async onSubmit(event) {
      const $this = this;
      $this.disableSubmitBtn = true;
      $this.$v.$touch();
      if ($this.$v.$invalid) {
        $this.$store.dispatch("showtostrMessageNotifications", {
          message: "Please fill all the fields to proceed form!",
          type: "error"
        });
        $this.disableSubmitBtn = false;
        return;
      }
      if (this.$refs.createPost.dropzone.files.length > 0) {
        this.$refs.createPost.processQueue();
      } else {
        const data = $this.$store.dispatch("postHttp", {
          url: "thread-message-reply/store",
          formDetails: this.formData,
          formEvent: event.target
        });
        await data.then(response => {
          if (typeof response !== "undefined") {
            $this.$store.dispatch("displayMessageErrors", {
              response: response,
              formEvent: event.target
            });
            $this.getMessagesData(1);
            $this.disableSubmitBtn = false;
            $this.formData.messagebody = "";
            $this.$v.$reset();
          }
        });
        $this.disableSubmitBtn = false;
      }
    },
    whenRouteUpdate(thread_new_id) {},
    sendingEvent(file, xhr, formData) {
      formData.append("messagebody", this.formData.messagebody);
      formData.append("project_id", this.formData.project_id);
      formData.append("thread_id", this.formData.thread_id);
    },
    successEvent(file, response) {},
    multipleUploadComplete(files, response) {
      const $this = this;
      $this.formData.messagebody = "";
      $this.$v.$reset();
      $this.getMessagesData();
      $this.$store.dispatch("showtostrMessageNotifications", {
        message: response.message,
        type: response.status
      });
      files.forEach(function(file) {
        file.previewElement.remove();
      });
      $(".dropzone").removeClass("dz-started");
      $this.disableSubmitBtn = false;
    },
    multipleServerError(files, response) {
      const $this = this;
      $this.$store.dispatch("showtostrMessageNotifications", {
        message: response.message,
        type: response.status ? response.status : "error"
      });
      this.disableSubmitBtn = false;
    },
    errorEvent(file, errorMessage, xhr) {
      file.status = "queued";
      return file.previewElement.classList.remove("dz-error");
    },
    LogValue() {},
    async getThreadProjectDetails(thread_id = null) {
      const $this = this;
      // $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "thread-message-reply/get-project-detail/" +
          (thread_id === null ? this.$route.params.thread_id : thread_id) +
          "?" +
          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_details = response.data;
          $this.formData.project_id = response.data.data.project_details.id;
          $this.getMessagesData();
          $this.loader = false;
        }
      });
    },
    async getMessagesData(page = 1, action = null) {
      if(action !== null) {
        this.$store.dispatch("generalLoader", {
          response: true
        });
      } else {
        this.data_loader = true;
      }

      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "thread-message/show/" +
          this.$route.params.thread_id +
          "?" +
          "&page=" +
          page +
          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          if (action) {
            response.data.data.forEach(messages => {
              $this.thread_message_detail.data.unshift(messages);
            });
          } else {
            $this.thread_message_detail = response.data;
            $this.thread_message_detail.data.reverse();
          }
          if (response.data.meta.current_page < response.data.meta.last_page) {
            // List View Update
            $this.load_more_btn = true;
            $this.nextPageNumber = response.data.meta.current_page + 1;
          } else {
            $this.load_more_btn = false;
          }
          $this.data_loader = false;
          $this.$store.dispatch("generalLoader", {
            response: false
          });
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    doNothing: function() {
      // Do nothing or some other stuffs
    },
    async deletFile(dialog, dataArray) {
      const $this = this;
      let message_in = dataArray.message_index;
      let file_in = dataArray.file_index;
      let file_data =
        $this.thread_message_detail.data[message_in].getFiles[file_in];
      const data = $this.$store.dispatch("deleteHttp", {
        url: "files/delete/" + file_data.id
      });
      await data.then(response => {
        if (typeof response !== "undefined") {
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: response.data.message,
            type: response.data.status
          });
          // $this.getProjectFiles(1);
          $this.thread_message_detail.data[message_in].getFiles.splice(
            file_in,
            1
          );
          dialog.close();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
body .white-row.loader-route {
  top: 0;
  height: 150px !important;
}
.remove-margin-btm {
  margin-bottom: 0;
  padding: 7px 0 0;
  color: #3fae2a;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.loadMoreBtn {
  padding-bottom: 20px;
  a {
    text-decoration: none;
    letter-spacing: 0.28px;
    color: #35c465;
    line-height: 20px;
  }
}
.project-show {
  .project-content-area {
    padding: 30px 0 0px;
  }
  section {
    &.main-title-header {
      padding: 0px 0 25px;
      border-bottom: 2px solid #ebebeb;
      h4 {
        margin: 0;
        padding: 0;
        letter-spacing: 0px;
        color: #313f53;
        font-weight: 400;
        font-size: 24px;
        span {
          font-weight: bold;
        }
      }
      .top-date {
        p {
          margin: 0;
          padding: 6px 0 0;
          letter-spacing: 0.28px;
          color: #2a1c0b;
        }
      }
    }
    &.message-listing {
      padding: 25px 0 0;
      p.right-side-para-message {
        padding-top: 0;
        position: absolute;
        right: 0;
        top: 7px;
      }
      .thread-row {
        border-bottom: 1px solid #ebebeb;
        padding-top: 25px;
        &:first-child {
          padding-top: 0;
        }
        .thread-area {
          position: relative;
          padding-left: 65px;
          .user-img {
            position: absolute;
            left: 0;
            top: 0;
          }
          .user-name {
            margin: 0;
            letter-spacing: 0.28px;
            color: #313f53;
            font-weight: 600;
            line-height: 35px;
          }
          .ThreadeBody * {
            letter-spacing: 0.28px;
            color: #2a1c0b;
            margin: 0;
            line-height: 30px;
          }
        }
      }
      .files-listing {
        padding-top: 10px;
        .file-listing-wrapper {
          text-align: center;
          padding-bottom: 25px;
          .file-box {
            border: 1px solid #ece9df;
            border-radius: 10px;
            height: 210px;
            max-width: 100%;
            position: relative;
            display: table;
            width: 100%;
            padding: 15px;
            max-height: 210px;
            overflow: hidden;
            background: #ece9df;
            .img-box {
              display: table-cell;
              vertical-align: middle;
              img {
                width: 85%;
              }
            }
            .icons-wrap {
              position: absolute;
              top: 5px;
              z-index: 3;
              width: 80px;
              right: 6px;
              .icons-bar {
                display: inline-block;
                margin-left: 8px;
                a {
                  color: #323f54;
                  svg {
                    cursor: pointer;
                  }
                  &:hover {
                    svg {
                      color: #3fae2a;
                    }
                  }
                }
              }
            }
          }
          .file-details-box {
            padding: 0 10px;
            h5 {
              letter-spacing: 0.28px;
              color: #323f54;
              font-size: 14px;
              margin: 0;
              line-height: 49px;
              font-weight: bold;
            }
            p {
              display: -webkit-box;
              max-width: 100%;
              height: 41px;
              margin: 0 auto;
              line-height: 1;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 20px;
              letter-spacing: 0.24px;
              color: #2a1c0b;
              font-size: 12px;
            }
          }
        }
      }
    }
    &.submit-form-area {
      padding-top: 25px;
      .form-actions {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}

// Extra large devices (lg and less)
@media screen and (max-width: 1199px) {
}
// Extra large devices (md and less)
@media screen and (max-width: 991px) {
}

// Extra large devices (sm and up)
@media screen and (max-width: 767px) {
  .project-show section.main-title-header h4 {
    font-size: 17px;
    line-height: 27px;
  }
}

// Extra large devices (xs and up)
@media screen and (max-width: 575px) {
  .project-show section.message-listing .thread-row .thread-area {
    position: relative;
    padding-left: 0;
  }
  .project-show section.message-listing .thread-row .thread-area .user-img {
    position: relative;
  }
  .project-show section.message-listing p.right-side-para-message {
    padding-top: 0;
    position: relative;
    right: 0;
    top: 0;
    margin-bottom: 0;
  }
}

// Extra large devices (xl, 1200px and up)
@media (min-width: 1200) {
}
</style>
